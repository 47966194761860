import Core from "@uppy/core";
import Webcam from "@uppy/webcam";
import Dashboard from "@uppy/dashboard";
import Form from "@uppy/form";
import StatusBar from "@uppy/status-bar";
import XHRUpload from "@uppy/xhr-upload";
import German from "@uppy/locales/lib/de_DE"

require('uppy/dist/uppy.css')
require('./form.scss')
// noinspection JSUnresolvedVariable
const endpoint = PRODUCTION ? "https://umfrage.raumforscherinnen.at" : "http://localhost:8000"
let canSubmit = true;

const allInputs = document.querySelectorAll("input,textarea")
const progress = document.getElementById("progress")
const contactYes = document.getElementById("id_contactYesNo_0")
const contactNo = document.getElementById("id_contactYesNo_1")
const copyrightYes = document.getElementById("id_copyrightYesNo_0")
const copyrightNo = document.getElementById("id_copyrightYesNo_1")
const contactInputs = document.getElementById("contactInputs")
const nameInput = document.getElementById("nameInput")
const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
const category = document.getElementById("id_kategorie")
const custom = document.getElementById("hide-custom")

function updateUploadButton(uppy) {
    const extra = document.getElementById("extraUploadWrapper")
    const clearAll = document.getElementById("clearAll")
    const submit = document.getElementById("submit")
    const unfinishedFiles = uppy.getFiles().filter(function (file) {
        return !file.progress.uploadComplete;
    })
    if (uppy.getFiles().length > 0) {
        clearAll.style.display = "block";

    } else {
        clearAll.style.display = "none";
    }
    if (unfinishedFiles.length > 0) {
        extra.style.display = "block";
        submit.disabled = true
        canSubmit = false
    } else {
        extra.style.display = "none"
        submit.disabled = false
        canSubmit = true
    }
}

function handleContact() {

    if (contactYes.checked) {
        contactInputs.style.display = "flex"
    } else {
        contactInputs.style.display = "none"
    }
    if (contactYes.checked || copyrightYes.checked) {
        nameInput.style.display = "block"
    } else {
        nameInput.style.display = "none"
    }
}

function handleCustom(category) {
    if (category.options[category.selectedIndex].text === "Eigene Kategorie") {
        custom.style.display = "block"
    } else {
        custom.style.display = "none"
    }
}


if (document.getElementById("upload")) {


    document.getElementById("form").addEventListener("submit", function (event) {
        if (!canSubmit) {
            event.preventDefault();
            return false
        }
    })

    const uppy = Core({debug: false})
        .use(Dashboard, {
            inline: true,
            target: '#upload',
            plugins: ['Webcam'],
            showLinkToFileUploadResult: false,
            // note: "Das ist ein Hinweis",
            hidePauseResumeCancelButtons: true,
            width: 1500,
            height: 450,
            locale: German,
            doneButtonHandler: null

        })
        .use(XHRUpload, {
            endpoint: endpoint + '/upload',
            headers: {'X-CSRFToken': csrftoken},
            fieldName: 'file',
            getResponseError(responseText, xhr) {
                return new Error(JSON.parse(responseText).errors);
            },
            locale: German
        })
        .use(Form, {
            target: "#form",
            getMetaFromForm: false,
            locale: German
        })
        .use(StatusBar, {
            target: "#extraUpload",
            showProgressDetails: true,
            locale: German,
        })
    window.uppy = uppy
    uppy.on('cancel-all', () => {
        const request = new XMLHttpRequest();
        request.open("POST", endpoint + "/delete");
        request.setRequestHeader('X-CSRFToken', csrftoken);
        request.addEventListener("load", function () {
            alert("gelöscht")
        });
        request.send();
    })

    document.getElementById("clearAll").addEventListener("click", function () {
        uppy.cancelAll()
    })


    uppy.on("file-added", function () {
        updateUploadButton(uppy)
    })
    uppy.on("file-removed", function () {
        updateUploadButton(uppy)
    })
    uppy.on("upload-success", function () {
        updateUploadButton(uppy)
    })
    uppy.on("upload-error", function () {
        updateUploadButton(uppy)
    })
    uppy.on("upload-retry", function () {
        updateUploadButton(uppy)
    })
    uppy.on("cancel-all", function () {
        updateUploadButton(uppy)
    })


    const textInputs = [];
    let formHasChanged = false;
    for (let i = 0; i < allInputs.length; ++i) {
        if ((allInputs[i].type === "text" || allInputs[i].type === "textarea") && allInputs[i].name !== "custom") {
            textInputs.push(allInputs[i])
        }
    }
    textInputs.forEach(function (input) {
        input.addEventListener("change", function () {
            let finishedInputs = 0
            formHasChanged = true
            for (let i = 0; i < textInputs.length; ++i) {
                if (textInputs[i].value) {
                    finishedInputs += 1
                }
            }
            progress.style.width = finishedInputs / textInputs.length * 100 + "%"
        });
    });


    category.addEventListener("change", function () {
        handleCustom(category)
    })
    handleCustom(category)


    contactYes.addEventListener("change", function () {
        handleContact();
    })
    contactNo.addEventListener("change", function () {
        handleContact();
    })
    copyrightYes.addEventListener("change", function () {
        handleContact();
    })
    copyrightNo.addEventListener("change", function () {
        handleContact();
    })
    handleContact()

    // http://stackoverflow.com/a/11766745
    let submitted = false;


    document.getElementById("form").addEventListener("submit", function () {
        submitted = true;
    })
    window.addEventListener("beforeunload", function (e) {
        if (formHasChanged && !submitted) {
            const confirmationMessage = "Die Änderungen wurden nicht gespeichert.";
            e.returnValue = confirmationMessage;     // Gecko and Trident
            return confirmationMessage;              // Gecko and WebKit
        }
    });


}
